let ejs = require('ejs')
import stockInList from 'raw-loader!../views/template/stockInList.ejs'
import stockInDetail from 'raw-loader!../views/template/stockIndetail.ejs'
import stockOutList from 'raw-loader!../views/template/stockOutList.ejs'
import stockOutDetail from 'raw-loader!../views/template/stockOutDetail.ejs'

export default {
  exportStockInList(doc) {
    let data = doc
    try {
        let result = ejs.render(stockInList, data)
        return result
    } catch (e) {
        console.error(e)
    }
  },
  exportStockInDetail(doc) {
    let data = doc
    try {
        let result = ejs.render(stockInDetail, data)
        return result
    } catch (e) {
        console.error(e)
    }
  },
  exportStockOutList(doc) {
    let data = doc
    try {
        let result = ejs.render(stockOutList, data)
        return result
    } catch (e) {
        console.error(e)
    }
  },
  exportStockOutDetail(doc) {
    let data = doc
    try {
        let result = ejs.render(stockOutDetail, data)
        return result
    } catch (e) {
        console.error(e)
    }
  },
};
